import { css } from "styled-components";

export const ResetConfigStyled = css`
  * {
    margin: 0;
    padding: 0;
    scrollbar-color: "#c1c1c1 #f1f1f1";
  }
  /* Tùy chỉnh thanh cuộn cho các trình duyệt WebKit */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  body {
    color: "#222222";
    word-break: break-word;
    font-size: 14px;
  }
  a {
    color: "#5656A2";
    &:hover {
      color: "#2a2a86";
    }
  }
  ul {
    padding-left: 15px;
  }
  .mantine-Table-tr[data-selected] {
    background-color: #228be61a;
  }
`;
