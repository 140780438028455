import { css } from "styled-components";

export const ButtonConfigStyled = css`
  .mantine-Button-root {
    &[data-size="xs"] {
      height: 24px;
      font-size: 12px;
      padding: 0px 12px;
    }

    &[data-size="sm"] {
      height: 32px;
      font-size: 14px;
      padding: 0px 16px;
    }

    &[data-size="md"] {
      height: 40px;
      font-size: 16px;
      padding: 0px 20px;
    }

    &[data-size="lg"] {
      height: 48px;
      font-size: 18px;
      padding: 0px 24px;
    }

    &[data-size="xl"] {
      height: 56px;
      font-size: 20px;
      padding: 0px 32px;
    }
    &[data-disabled="true"] {
      background: var(--mantine-color-gray-2);
    }
  }
`;
