import { notifications } from "@mantine/notifications";

interface NotificationsProps {
  title: string;
  message: string;
  color: string;
}

const baseNotifications = (data: NotificationsProps) =>
  notifications.show({
    //   id: "hello-there",

    withCloseButton: true,
    // onClose: () => console.log("unmounted"),
    // onOpen: () => console.log("mounted"),
    autoClose: 3000,
    title: data.title,
    message: data.message,
    color: data.color,
    //   icon: X,
    className: "my-notification-class",
    // style: { backgroundColor: "white" },
    loading: false,
  });

const ydNotifications = {
  success: (message: string) =>
    baseNotifications({
      title: "Thông báo",
      message: message,
      color: "green",
    }),
  error: (message: string) =>
    baseNotifications({
      title: "Thông báo",
      message: message,
      color: "red",
    }),
};
export default ydNotifications;
