import { AxiosPromise } from "axios";
import AccountPublicResponse from "models/account-public/account-public.response";
import AccountResponse from "models/account/account.response";
import { axiosInstanceAdmin } from "shared/axios-interceptors";

const getAccountPublicByName = (
  name: string
): AxiosPromise<AccountPublicResponse[]> => {
  return axiosInstanceAdmin.get(
    `/admin/users.json?search=${name}&activated=true`
  );
};
const getAccountByLogin = (): AxiosPromise<AccountResponse> => {
  return axiosInstanceAdmin.get(`/admin/account.json`);
};
const logoutService = (): AxiosPromise<any> => {
  return axiosInstanceAdmin.post(`/admin/logout.json`);
};

const accountService = {
  getAccountByLogin,
  logoutService,
  getAccountPublicByName,
};

export default accountService;
